<template>
    <v-container class="pa-8">
        <div class="text-h5 font-weight-bold grey--text text--darken-2 mb-12">
            <v-icon>mdi-table-large</v-icon>
            Risk Map & Movement

            <v-btn color="primary" class="ml-4 float-right text-body-1" @click="coverageDrawer = !coverageDrawer">
                <v-icon class="mr-1">mdi-calendar-multiple</v-icon>
                Filter Coverage
            </v-btn>

            <v-select
                :items="returnDept"
                v-model="deptFilter"
                label="Select Department"
                class="float-right text-body-1"
                outlined
                v-show="returnORMPFilter"
                dense
                @input="unmodifyDataToShowForResidual(null),coverageFilterKey = null"
            ></v-select>
        </div>
        <div class="text-h4 font-weight-bold grey--text text--darken-2 mb-6">
            <span v-show="returnORMPFilter">{{deptFilter}} </span> 
            <span v-if="returnRiskMapCoverageTitle !== ''">[{{returnRiskMapCoverageTitle}}]
                <span class="grey--text" v-if="tab <= 1"> ({{riskHistory[tab]}})</span>
                <span class="grey--text" v-else> *(RISK TRANSPIRED)</span>
            </span>
            <span v-else class="secondary--text">
                <v-icon class="secondary--text">mdi-alert </v-icon> 
                NO SELECTED COVERAGE
            </span>
            <!-- <span v-else>{{$store.getters['useraccount/isAuthenticated'].department}}</span>
            Department  -->

        </div>
        <div>
            <v-tabs
                v-model="tab"
                background-color="grey lighten-4"
            >
                <v-tab
                    v-for="item in riskHistory"
                    :key="item"
                    active-class="primary lighten-5"
                >               
                    {{ item }}
                </v-tab>
                <v-tab 
                    v-show="returnTabInfoRiskTranspired.length > 0"
                    v-for="item in returnTabInfoRiskTranspired"
                    :key="item['.key']"
                    active-class="primary lighten-5"
                    @click="item.RiskManagementLevel == 'Inherent' ? f(item['.key']) : modifyDataToShowForResidual(item.CoverageId)"
                >
                <span v-if="item.RiskManagementLevel == 'Inherent'">
                    *({{$moment(item.CreatedAt.toDate()).format('L')}})
                    <br><span class="text-caption">Inherent</span> 
                </span>
                <span v-else>
                    *({{$moment(item.startDate.toDate()).format('L')}})
                    <br><span class="text-caption">Residual</span> 
                </span>
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item
                    v-for="item in riskHistory"
                    :key="item"
                >
                    <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col class="col">
                                <v-card elevation="1" class="pr-8 py-4">
                                <div class="text-center"> 
                                    <p class="text-body-1 font-weight-bold pl-16 grey--text">Impact</p> 
                                </div>
                                <v-row>
                                    <v-col class="col-1 pa-0 pt-16" style="max-width:100%;">
                                        <p class="text-center font-weight-bold grey--text text-body-1 mt-16" style="transform: rotate(-90deg);">Likelihood</p> 
                                    </v-col>
                                    <v-col class="col pa-0 ma-0">
                                    
                                        <v-simple-table>
                                            <template v-slot:default>
                                            <thead>
                                                <tr>
                                                <th class="text-left px-6">
                                                    
                                                </th>
                                                <th class="text-left " v-for="item in RiskImpactLevel" :key="item.Level">
                                                    {{item.Level}} 
                                                    <v-tooltip bottom >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon x-small class="grey--text" v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
                                                        </template>
                                                        <span>Level: {{item.Level}} <br> {{item.NIAT}} <br> {{item.MGTACTION}}</span>
                                                    </v-tooltip>
                                                </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                v-for="(item,idx) in LikelihoodLevel"
                                                :key="item.Level"
                                                class="pa-0 ma-0"
                                                >
                                                
                                                <td class="font-weight-bold grey--text text--darken-1" v-show="RiskImpactLevel">
                                                    {{ item.Level }}
                                                    <v-tooltip bottom >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon x-small class="grey--text" v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
                                                        </template>
                                                        <span>Level: {{item.Level}} <br> {{item.Frequency}} <br> {{item.Probability}}</span>
                                                    </v-tooltip>
                                                </td>
                                                <td v-for="x in 5 " :key="x"  class="pa-1"  :style="`border: solid 1px #333333;background-color: ${riskMapColors[idx].colors[x-1]};`">
                                                    <v-tooltip 
                                                        bottom 
                                                        v-for="badge in returnBadgeMap(x,item.Level)"
                                                        :key="badge.RiskNo"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-chip
                                                                color="grey darken-2"
                                                                class="ma-1 pa-2"
                                                                dark
                                                                v-bind="attrs" 
                                                                v-on="on"
                                                                x-small
                                                            >
                                                            {{badge.RiskNo}}
                                                            </v-chip>
                                                        </template>
                                                        {{badge.RiskName}}
                                                    </v-tooltip>
                                                </td>
                                                </tr>
                                            </tbody>
                                            </template>
                                        </v-simple-table>  

                                    </v-col>
                                </v-row>   
                                </v-card>
                            </v-col>
                            <v-col class="col">
                                <v-data-table
                                    :headers="headers"
                                    :items="returnMovementORMP"
                                    class="elevation-1"
                                >
                                   
                                </v-data-table>
                                
                            </v-col>
                        </v-row>
                    </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item 
                    v-show="returnTabInfoRiskTranspired.length > 0"
                    v-for="item in returnTabInfoRiskTranspired"
                    :key="item['.key']"
                >
                    <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col class="col">
                                <v-card elevation="1" class="pr-8 py-4">
                                <div class="text-center"> 
                                    <p class="text-body-1 font-weight-bold pl-16 grey--text">Impact</p> 
                                </div>
                                <v-row>
                                    <v-col class="col-1 pa-0 pt-16" style="max-width:100%;">
                                        <p class="text-center font-weight-bold grey--text text-body-1 mt-16" style="transform: rotate(-90deg);">Likelihood</p> 
                                    </v-col>
                                    <v-col class="col pa-0 ma-0">
                                    
                                        <v-simple-table>
                                            <template v-slot:default>
                                            <thead>
                                                <tr>
                                                <th class="text-left px-6">
                                                    
                                                </th>
                                                <th class="text-left " v-for="item in RiskImpactLevel" :key="item.Level">
                                                    {{item.Level}} 
                                                    <v-tooltip bottom >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon x-small class="grey--text" v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
                                                        </template>
                                                        <span>Level: {{item.Level}} <br> {{item.NIAT}} <br> {{item.MGTACTION}}</span>
                                                    </v-tooltip>
                                                </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                v-for="(item,idx) in LikelihoodLevel"
                                                :key="item.Level"
                                                class="pa-0 ma-0"
                                                >
                                                
                                                <td class="font-weight-bold grey--text text--darken-1" v-show="RiskImpactLevel">
                                                    {{ item.Level }}
                                                    <v-tooltip bottom >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon x-small class="grey--text" v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
                                                        </template>
                                                        <span>Level: {{item.Level}} <br> {{item.Frequency}} <br> {{item.Probability}}</span>
                                                    </v-tooltip>
                                                </td>
                                                <td v-for="x in 5 " :key="x"  class="pa-1"  :style="`border: solid 1px #333333;background-color: ${riskMapColors[idx].colors[x-1]};`">
                                                    <v-tooltip 
                                                        bottom 
                                                        v-for="badge in returnBadgeMap(x,item.Level)"
                                                        :key="badge.RiskNo"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-chip
                                                                color="grey darken-2"
                                                                class="ma-1 pa-2"
                                                                dark
                                                                v-bind="attrs" 
                                                                v-on="on"
                                                                x-small
                                                            >
                                                            {{badge.RiskNo}}
                                                            </v-chip>
                                                        </template>
                                                        {{badge.RiskName}}
                                                    </v-tooltip>
                                                </td>
                                                </tr>
                                            </tbody>
                                            </template>
                                        </v-simple-table>  

                                    </v-col>
                                </v-row>   
                                </v-card>
                            </v-col>
                            <v-col class="col">
                                <v-data-table
                                    :headers="headers"
                                    :items="returnMovementORMP"
                                    class="elevation-1"
                                >
                                   
                                </v-data-table>
                                
                            </v-col>
                        </v-row>
                    </v-card-text>
                    </v-card>                    
                </v-tab-item>
            </v-tabs-items>

        </div>
    <v-navigation-drawer
        absolute
        right
        temporary
        width="350"
        v-model="coverageDrawer"
    >
    <div class="text-h6 pa-4 grey--text text--darken-2">
        <v-icon class="mr-2">mdi-calendar-multiple</v-icon>
        Filter Coverage
    </div>
        <v-timeline
            dense
            clipped
            class="pr-8"
        >
            <v-timeline-item
                v-for="event in returnCoverage"
                :key="event['.key']"
                class="mb-4"
                :class="coverageFilterKey == event['.key'] ? 'primary lighten-2' : ''"
                style="cursor:pointer;"
                :color="coverageFilterKey == event['.key'] ? 'primary' : 'grey'"
                small
                @click.native="coverageFilterKey = event['.key']"
            >
                <template v-slot:icon>
                    <v-icon class="white--text" v-show="coverageFilterKey == event['.key']">mdi-check</v-icon>
                </template>
                <v-row justify="space-between">
                    <v-col
                    cols="7"
                    v-text="event.startDate"
                    ></v-col>
                    <v-col
                    class="text-right text-caption pr-8"
                    cols="5"
                    >
                        {{$moment(event.CreatedAt.toDate()).format('MM/DD/YYYY HH:mm A')}}
                    </v-col>
                </v-row>
            </v-timeline-item>

        </v-timeline>
    </v-navigation-drawer>
    </v-container>
</template>
<script>

export default {
    data: () => ({
        coverageDrawer: false,
        riskHistory: ['Inherent','Residual'],
        riskMapLikelihood: [5,4,3,2,1],
        riskMapColors: [
            {colors: ['orange','orange','red','red','red']},
            {colors: ['yellow','orange','orange','red','red']},
            {colors: ['green','yellow','orange','red','red']},
            {colors: ['green','green','yellow','orange','red']},
            {colors: ['green','green','yellow','orange','orange']},
        ],
        headers: [
            { text: 'Risk No.', sortable: true, value: 'RiskNo' },
            { text: 'Risk Name', sortable: true, value: 'RiskName' },
            { text: 'L', sortable: true, value: 'L' },
            { text: 'I', sortable: true, value: 'I' },
            { text: 'V', sortable: true, value: 'V' },
        ],
        value: '',
        tab: 0,
        deptFilter: 'SYSDEV',
        coverageFilterKey: null,
        selectedRiskTranspiredKey: null,
        notInherentRiskTranspired: false,
    }),
    firestore(){
        return {
            RiskImpactLevel: this.$db.collection('RiskImpactLevel').orderBy('Level','asc'),
            LikelihoodLevel: this.$db.collection('LikelihoodLevel').orderBy('Level','desc'),
            ORMP: this.$db.collection('ORMP').orderBy('Vulnerability','desc'),
            ORMPLevels: this.$db.collection('ORMPLevels'),
            ORMPCoverage: this.$db.collection('ORMPCoverage'),
            Departments: this.$db.collection('Departments')
        }
    },
    computed:{
        returnCoverage(){
            if(this.returnORMPFilter == false){
                let filter = this.ORMPCoverage.filter(a=>{
                    return a.Department = this.$store.getters['useraccount/isAuthenticated'].department && a.isTranspired !== true
                })   
                return this.$lodash.orderBy(filter,'CreatedAt','desc')
            }

            let deptFilter = this.ORMPCoverage.filter(a=>{
                return a.Department == this.deptFilter
            })

            return this.$lodash.uniqBy(this.$lodash.orderBy(deptFilter,a=>{
                return this.$moment(a.startDate)
            },'desc'),'startDate')
        },
        returnORMPFilter(){
            if(this.$store.getters['useraccount/isAuthenticated'].department !== 'RISK MANAGEMENT TEAM') {
                return false
            }
            return true
        },
        returnMovementORMP(){
            // get department if ever
            let key = null
            if(this.coverageFilterKey == null && this.returnCoverage.length > 0) {
                key = this.returnCoverage[0]['.key']
                this.passOnValue(key)
            } else {
                key = this.coverageFilterKey
            } 

            let coverageFilter = this.ORMP.filter(a=>{
                return a.CoverageId == key
            })

            console.log(coverageFilter)
            let ORMP = coverageFilter.map((a,index)=>{
                let data = {...a}
                delete data.RiskName
                return {
                    RiskNo: index + 1,
                    RiskName: a.RiskName.RiskName,
                    L: a.LikelihoodLevel.Level,
                    I: a.ImpactLevel.Level,
                    V: a.Vulnerability,    
                    ...data          
                }
            })

            let dataToShow = []
            dataToShow = ORMP

            if(this.tab == 1){
                let coverageFilterLevels = this.ORMPLevels.filter(a=>{
                    return a.CoverageId == key
                })
                let residual = []
                let levels = coverageFilterLevels.filter(a=>{
                    return a.RiskManagementLevel == 'Residual'
                })
                ORMP.forEach(a=>{
                    levels.forEach(x=>{
                        if(x.ORMPId == a['.key']){
                            x.RiskName = a.RiskName
                            residual.push(x)
                        }
                    })
                })

                let orderBy = this.$lodash.orderBy(residual,'Vulnerability','desc')

                dataToShow = orderBy.map((a,index)=>{
                    let data = {...a}
                    delete data.RiskName
                    return {
                        RiskNo: index + 1,
                        RiskName: a.RiskName,
                        L: a.LikelihoodLevel.Level,
                        I: a.ImpactLevel.Level,
                        V: a.Vulnerability,    
                        ...data             
                    }
                })
            } else if(this.tab > 1) {
                console.log(this.returnTabInfoRiskTranspired,'risk transpired');
                let getKey = this.selectedRiskTranspiredKey
                let coverageFilterTS = this.ORMP.filter(a=>{
                    return a.CoverageId == getKey
                })

                console.log(coverageFilterTS,'coverageFilterTS')
                let ORMPTS = coverageFilterTS.map((a,index)=>{
                    let dataTS = {...a}
                    delete dataTS.RiskName
                    return {
                        RiskNo: index + 1,
                        RiskName: a.RiskName.RiskName,
                        L: a.LikelihoodLevel.Level,
                        I: a.ImpactLevel.Level,
                        V: a.Vulnerability,    
                        ...dataTS          
                    }
                })
                
                dataToShow = ORMPTS

                if(this.notInherentRiskTranspired == true){
                    let coverageFilterLevelsTS = this.ORMPLevels.filter(a=>{
                        return a.CoverageId == getKey
                    })

                    console.log(coverageFilterLevelsTS,'coverageFilterLevelsTS');
                    let residualTS = []
                    let levelsTS = coverageFilterLevelsTS.filter(a=>{
                        return a.RiskManagementLevel == 'Residual'
                    })
                    ORMP.forEach(a=>{
                        levelsTS.forEach(x=>{
                            if(x.ORMPId == a['.key']){
                                x.RiskName = a.RiskName
                                residualTS.push(x)
                            }
                        })
                    })

                    let orderByTS = this.$lodash.orderBy(residualTS,'Vulnerability','desc')

                    dataToShow = orderByTS.map((a,index)=>{
                        let data = {...a}
                        delete data.RiskName
                        return {
                            RiskNo: index + 1,
                            RiskName: a.RiskName,
                            L: a.LikelihoodLevel.Level,
                            I: a.ImpactLevel.Level,
                            V: a.Vulnerability,    
                            ...data             
                        }
                    })     
                    
                    console.log(dataToShow,'residual');
                }



            }

            if(this.returnORMPFilter == false){
                let filter = dataToShow.filter(a=>{
                    return a.Department = this.$store.getters['useraccount/isAuthenticated'].department
                })   
                return filter
            }

            let deptFilter = dataToShow.filter(a=>{
                return a.Department == this.deptFilter
            })

            return deptFilter
        },
        returnDept(){
            let db = this.Departments.map(a=>{
                return {
                    text: a.departmentName,
                    value: a.departmentName
                }
            })
            return db.filter(a=>{
                return a.text !== 'RISK MANAGEMENT TEAM'
            })
        },
        returnTabInfoRiskTranspired(){
            console.log(this.returnCoverage,'get');

            if(this.ORMPCoverage.length == 0) return []

            let selected = this.ORMPCoverage.filter(a=>{
                return a['.key'] == this.coverageFilterKey
            })[0]

            if(selected == null || selected.startDate == undefined) return []


            let getCov = this.ORMPCoverage.filter(a=>{
                return this.$moment(a.startDate).format('MMM YYYY') == this.$moment(selected.startDate).format('MMM YYYY') && a.isTranspired == true 
                // && a.isApproved == true
            })
            console.log(getCov,'getCov');

            let out = []

            getCov.forEach(a=>{
                let levels = this.getCoverageLevels(a['.key'])
                let residual = levels[0]
                let inherent = {...a,RiskManagementLevel: 'Inherent'}
                if(levels.length > 0){
                    out.push(inherent)
                    out.push({...residual,startDate: inherent.CreatedAt})
                } else{
                    out.push(inherent)
                }
            })

            console.log(out,'out');

            return this.$lodash.orderBy(out,'CreatedBy','desc')
        },
        returnRiskMapCoverageTitle(){
            if(this.coverageFilterKey == null) return ''
            let filter = this.ORMPCoverage.filter(a=>{
                return a['.key'] == this.coverageFilterKey
            })[0]
            return filter.startDate
        }
    },
    methods:{
        modifyDataToShowForResidual(coverage){
            this.selectedRiskTranspiredKey = coverage
            this.notInherentRiskTranspired = true
        },
        unmodifyDataToShowForResidual(coverage){
            this.selectedRiskTranspiredKey = coverage
            this.notInherentRiskTranspired = false
            console.log(this.selectedRiskTranspiredKey,'this.selectedRiskTranspiredKey');
        },
                
        getCoverageLevels(covkey){
            return this.ORMPLevels.filter(a=>{
                return a.CoverageId == covkey && a.RiskManagementLevel !== 'Inherent'
            })
        },
        returnBadgeMap(impact,likelihood){
            return this.returnMovementORMP.filter(a=>{
                return a.I == impact && a.L == likelihood
            })
        },
        passOnValue(key){
            this.coverageFilterKey = key
        }
    }
}
</script>
<style scoped>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>